import { TableCell } from "@cambridgeassessment/cambridge-ui";
import { TableHead, TableRow, TableSortLabel,Tooltip } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Box } from "@mui/system";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from "react";
import {makeStyles} from '@mui/styles';
const EnhancedTableHead = (props) => {
  const useStyles = makeStyles({
    checkbox:{
      '&.Mui-checked':{
        '&:hover' :{
        backgroundColor: '#0076BD',
      },
       '&:focus':{
        backgroundColor: '#0076BD',
       },
      },
    },
  });

  const classes = useStyles();
  const state = useSelector(fnState=>fnState)
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [warning,setWarning] = useState('Warning')
  const { order, orderBy, onRequestSort, headCells,flagCheck,onSelectAllChange,selectAll } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property,event,"propertyevent")
    onRequestSort(event, property);
  };
  const handleSelectAll = (e) =>
  {
    onSelectAllChange(e.target.checked);
    
  }
  const message = () =>
  {
  return (<span><b>Warning:</b> By using this 'select all check-box', you will be amending the estimated completion date for <b>all</b> candidates currently selected by your filters, including those on subsequent pages.</span>)
  }
  const getHeaderMappings = () => {
    if (props.inDetailViewTable) {
      return {
        "Specification code": "mainScheme",
        "Total GLH required": "expectedGlh",
        "Unit name": "unitAchievableName",
        "Last name": "candidateLastname",
        "First name": "candidateFirstname",
        Qualification: "qualification",
        "Unit GLH": "glhUnit",
        "Unit number": "unitNumber",
        "Unit Type": "unitType",
        "Unit achievement status": "finalResultStatus",
        "OCR candidate number": "ocrCandidateNumber",
        "Candidate number": "centreCandidateNo",
        "Estimated completion date": "certificationStatus",
        "Qualification result status": "finalQualificationResult",
      };
    }
    if (props.checkPointTable) {
      return {
        "Centre number": "centreNo",
        "Centre name": "centreName",
        "Modified by": "modifiedBy",
        "Modified on": "modifiedOn",
        "Checkpoint status": "checkPointStatus",
        "AuditLog":"AuditLog",
      };
    }
    if (props.certificationStatus) {
      return {
        "Centre number": "centreNo",
        "Candidate number": "centreCandidateNo",
        "OCR candidate number": "rsaCandidateNo",
        "First name": "candidateFirstname",
        "Last name": "candidateLastname",
        "Qualification": "qualification",
        "Specification code": "specificationCode",
        "Intended completion status": "certificationStatus",
        "Modified By": "lastUpdatedBy",
        "Modified On": "lastUpdatedTime",
      };
    }
    if (props.unitResultTable) {
      return {
        "Unit number": "unitNumber",
        "Unit name": "unitAchievableName",
        "Unit reference number": "unitReferenceNumber",
        "Unit type": "unitType",
        "Unit GLH" : "glhUnit",
        "Assessment type": "assessmentType",
        "Achievement status": "finalResultStatus",
      };
    }
    if (props.ineligibleCandidatesViewTable) {
      return {
          "Candidate number": "centreCandidateNo",
          "OCR candidate number": "rsaCandidateNo",
          "First name": "candidateFirstname",
          "Last name": "candidateLastname",
          "Qualification": "qualification",
          "Specification code": "specificationCode",
          "GLH achieved or in progress":"actualGlh",
          "Total GLH required": "expectedGlh",
          "Qualification result status": "resultStatus",
          "Main reason not eligible": "ineligiblereason",
          "Tell us why the candidate is at risk":"eligibilityoverride"
      };
    }
      if (props.ineligibleCandidateStatusTable) {
        return {
          "Centre number": "centreno",
          "OCR candidate number": "ocrcandidatenumber",
          "First name": "firstname",
          "Last name": "lastname",
          "Qualification": "qualification",
          "Specification code": "specificationcode",
          "Ineligible candidates": "resultStatus",
          "Modified By": "modifiedby",
          "Modified On": "modifiedon",
          "Tell us why the candidate is at risk":"eligibilityOverride"
        };
      }
    return {
      "Candidate number": "centreCandidateNo",
      "OCR candidate number": "rsaCandidateNo",
      "First name": "candidateFirstname",
      "Last name": "candidateLastname",
      Qualification: "qualification",
      "Specification code": "specificationCode",
      "Total GLH required": "expectedGlh",
      "GLH achieved or in progress":"actualGlh",
      "Estimated completion date": "certificationStatus",
      "Qualification result status": "resultStatus",
    };
  };
  let headerToRespMapping = getHeaderMappings();
  const displayedSortOrder= order === "desc" ? "sorted descending" : "sorted ascending"
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell}
            sortDirection={
              orderBy === headerToRespMapping[headCell] ? order : false
            } 
          >
          <div style={{display: 'flex'}}>
         {headCell ? headCell  === "Estimated completion date" && state.selectedOrg.role === 'EXAMS_OFFICER' ? <div style={{width: '40px'}}><Tooltip title ={message()}><Checkbox style={{display: flagCheck ? 'inline-block' : 'none'}} checked={selectAll} onChange={handleSelectAll} {...label}classes={{root: classes.checkbox }} /></Tooltip></div>  : '' : "value not found"}
            {headCell !== "History" ? <TableSortLabel
              active={orderBy === headerToRespMapping[headCell]}
              direction={
                orderBy === headerToRespMapping[headCell] ? order : "asc"
              }
              onClick={createSortHandler(headerToRespMapping[headCell])}
              >
              {headCell}
              {orderBy === headCell ? (
                <Box component="span">
                  {displayedSortOrder}
                </Box>
              ) : null}
            </TableSortLabel> : headCell}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default EnhancedTableHead;
